import PropTypes from "prop-types";
import get from "lodash.get";
import { Button, styled, theme } from "@washingtonpost/wpds-ui-kit";

const Text = styled("p", {
  fontFamily: theme.fonts.subhead,
  fontSize: theme.fontSizes["087"]
});

const StyledUl = styled("ul", {
  display: "none",
  "@media (min-width: 900px)": {
    $$navHeight: "48px",
    height: "$$navHeight",
    lineHeight: "$$navHeight",
    pointer: "cursor",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
    alignItems: "center",
    opacity: 1,
    width: "100%",
    display: "flex",
    padding: "8px 0",
    overflow: "hidden",
    borderBottom: "solid 1px $primary",
    justifyContent: "center",
    margin: "0px 0px 1px 0px"
  }
});

const StyledLi = styled("li", {
  flexBasis: "fit-content",
  display: "flex",
  alignItems: "center"
});

const HeaderNavLinks = (props) => {
  const { inlineLinkData } = props;

  return (
    <StyledUl data-qa="header-nav-links-ul">
      {inlineLinkData &&
        inlineLinkData.map((item, i) => {
          const name = get(item, "name", i);
          let url = get(item, "url", "");
          url = url
            ? `${url}?itid=hp_top_nav_${(name || "").toLowerCase()}`
            : url;

          return (
            <StyledLi key={`${name}-${url}`}>
              <Button
                as="a"
                href={url}
                css={{ border: "none" }}
                density="compact"
              >
                <Text>{name}</Text>
              </Button>
            </StyledLi>
          );
        })}
    </StyledUl>
  );
};

HeaderNavLinks.propTypes = {
  inlineLinkData: PropTypes.array,
  showLogo: PropTypes.bool
};

export default HeaderNavLinks;
